import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import Routes from './routes'
import history from '../history'
import {initializeAxios} from '../helpers/axios'
import ScrollToTop from '../helpers/scroll-to-top'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/main.css'

class App extends Component {
  
  constructor(props) {
    super(props)
    initializeAxios()
  }

  render() {
    return (
      <Router history={history} forceRefresh={true}>
        <ScrollToTop />
        <Routes />
      </Router>
    )
  }
}

export default App
