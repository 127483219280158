import React, { useEffect } from 'react'
import UserNavMenu from './user-nav-menu'
import { Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { showClientProfile } from '../actions/client'

const UserSideMenu = () => {
  const {profile} = useSelector(state => state.showClientProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    if(!profile){
      dispatch(showClientProfile())
    }
  },[])

  let imageStyle = {
    backgroundImage: 'url(' + process.env.REACT_APP_API_PUBLIC_FOLDER + 'storage/' +profile?.image_path+')'
  }

  return (
    <Col lg={5} className="lateral">
      <div className="green-box avatar">
        <div className="bkg"></div>
        { profile?.image_path !== null ? 
          <div className="profile-holder" style={imageStyle}></div>
          :
          <img 
            src={`${process.env.PUBLIC_URL}/images/icons/no-pic-profile.png`} 
            className="img-fluid" 
            alt="Pic profile"/>
        }
        <p>{profile?.name}<br/>{profile?.email}</p>
      </div>
      <div className="green-box menu">
        <UserNavMenu/>
      </div>
  </Col>
  )
}

export default UserSideMenu