import axios from 'axios'

export const SHOW_CART = 'SHOW_CART'
export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const CHANGE_CART_ITEM_QUANTITY = 'CHANGE_CART_ITEM_QUANTITY'
export const CALCULATE_SHIPMENT = 'CALCULATE_SHIPMENT'
export const CALCULATE_OFFICE_SHIPMENT = 'CALCULATE_OFFICE_SHIPMENT'
export const VALIDATE_COUPON = 'VALIDATE_COUPON'
export const RESTORE_CART = 'RESTORE_CART'


export const showCart = (with_possible_offices = "false") => ({
  type: SHOW_CART,
  payload: axios.get('/cart',{
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency'),
      with_possible_offices
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})


export const addCartItem = (productId, quantity) => ({
  type: ADD_CART_ITEM,
  payload: axios.post('/cart',{product_id: productId, quantity},{
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const removeCartItem = (cartItemId) => ({
  type: REMOVE_CART_ITEM,
  payload: axios.delete(`/cart/item/${cartItemId}`,{
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const changeCartItemQuantity = (cartItemId, quantity) => ({
  type: CHANGE_CART_ITEM_QUANTITY,
  payload: axios.put(`/cart/item/${cartItemId}`, {quantity}, {
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const calculateShipmentCost = (zip_code, country, country_code, city) =>  ({
  type: CALCULATE_SHIPMENT,
  payload: axios.post('/cart/shipment-calculator', {zip_code, country, country_code, city}, {
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

// export const calculateOfficeShipmentCost = (zip_code, country, country_code, city) =>  ({
//   type: CALCULATE_OFFICE_SHIPMENT,
//   payload: axios.post('/cart/office-shipment-calculator', {zip_code, country, country_code, city}, {
//     params: {
//       uuid: localStorage.getItem('entelequia-cart-uuid'),
//       currency: localStorage.getItem('entelequia-currency')
//     }
//   })
//     .then(response => response.data)
//     .catch(error => Promise.reject(error))
// })

export const validateCoupon = couponCode =>  ({
  type: VALIDATE_COUPON,
  payload: axios.post('/cart/coupon-validator', {couponCode}, {
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})

export const restoreCartAction = (orderId) => ({
  type: RESTORE_CART,
  payload: axios.post('/cart/restore',{order_id: orderId},{
    params: {
      uuid: localStorage.getItem('entelequia-cart-uuid'),
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})