import React, {useEffect, useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import history from '../history'

const CheckoutGetnet = props => { 
  const [order, setOrder ] = useState(null)
  const [paymentError, setPaymentError] = useState(false)
  // const [isLoadingScript, setIsLoadingScript] = useState(true)

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://www.pre.globalgetnet.com/digital-checkout/loader.js";
      script.async = true;
      script.setAttribute("data-testid", "digital-checkouta");
      script.setAttribute("id", "digital-checkoutb");
      
      script.onload = () => {
        resolve();
      };
      
      script.onerror = () => {
        reject(new Error("Script load error"));
      };
      
      document.body.appendChild(script);
    });
  }
  
  useEffect(() => {


    let {order: _order, paymentGetnetDetail: _paymentGetnetDetail} = props.location.state ?? {}
    // let _order = { id : 12345 }
    // let _paymentGetnetDetail = { payment_intent_id: "842d07b5-e6cf-4caa-8b1b-785a52cdd6c9", payment_access_token : "eyJ0eXAiOiJKV1QiLCJraWQiOiI1amhLMy9xK0ZpK0tTRkIrRUwwN3VhMFYwdGM9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJjaWRfMjYyZjJjMDItZDUwOS00MzYzLTk4ZmQtYmRhNzZmYzQ2N2E4IiwiY3RzIjoiT0FVVEgyX1NUQVRFTEVTU19HUkFOVCIsImF1ZGl0VHJhY2tpbmdJZCI6ImY4ZmFiNmJkLTFjODgtNDYyZS05OTRiLTVjZjlhYTMxN2Q0Ny01NTYwODAzNyIsInN1Ym5hbWUiOiJjaWRfMjYyZjJjMDItZDUwOS00MzYzLTk4ZmQtYmRhNzZmYzQ2N2E4IiwiaXNzIjoiaHR0cHM6Ly9hcGkucHJlLmdsb2JhbGdldG5ldC5jb20vb2F1dGgyL2NvbnN1bWVyIiwidG9rZW5OYW1lIjoiYWNjZXNzX3Rva2VuIiwidG9rZW5fdHlwZSI6IkJlYXJlciIsImF1dGhHcmFudElkIjoiVGdKQW80bHlpX2pIcWtyZEFCYkk2Zl85cTE0IiwiYXVkIjoiY2lkXzI2MmYyYzAyLWQ1MDktNDM2My05OGZkLWJkYTc2ZmM0NjdhOCIsIm5iZiI6MTcxODk4MTE1OSwiZ3JhbnRfdHlwZSI6ImNsaWVudF9jcmVkZW50aWFscyIsInNjb3BlIjpbImRpZ2l0YWwtcGF5bWVudHM6Y2hlY2tvdXQ6Y3VzdG9tIl0sImF1dGhfdGltZSI6MTcxODk4MTE1OSwicmVhbG0iOiIvY29uc3VtZXIiLCJleHAiOjE3MTg5ODQ3NTksImlhdCI6MTcxODk4MTE1OSwiZXhwaXJlc19pbiI6MzYwMCwianRpIjoia1RiUVRWd2ZtbHpYTHE5aUx3bm5CVjZPSEZ3IiwiY291bnRyeSI6IkFSIiwic2VsbGVySWQiOiI1YzMxY2NkNC0xNjgwLTQxZmYtOTQ2My04NDdmMWViNjBkNzMiLCJ0ZW5hbnQiOiJzYW50YW5kZXIifQ.IXqvWnaoUIoljPj3xJkExRH0_MnnHWxgwrg9DzHCCunhXfwMXKd43E33mHtwwPWueoxYUtEnlwXNv-zU6OLcRhzFXIiNc_aVv3ZBeLOANE8Q0AOwRJdj22Sdcr5KM3IAzgq9b22dNqA8YMXTcyG_uGBv6-pxiWvYJMR6h3fIzPjdWgGyRxciNVQj4dV2yUO8B_sX5VRw26gQZWhLnQE17pwDW1BGxIUFdWoTHGW9cHsF2woElm3ZyCdLqt6zW208XQbDEgrH6ZiqCAHM30ukYVXDcdTQKFnAQUE32lCpi5tQqeG9Ak9D4vPOZqFOVbi6Gs8nYAmACjoREaoP4qDw3w"}

    if(_order == null || _paymentGetnetDetail == null || _paymentGetnetDetail.payment_intent_id == null || _paymentGetnetDetail.payment_access_token == null){

      setPaymentError(true)
      
    } else{
        setOrder(_order)
        
        let entelequia_getnet_order = { order_id : _order.id, payment_intent_id : _paymentGetnetDetail.payment_intent_id}
        localStorage.setItem('entelequia_getnet_order', JSON.stringify(entelequia_getnet_order));

        console.log("_paymentGetnetDetail",_paymentGetnetDetail)
        console.log("Bearer " + _paymentGetnetDetail.payment_access_token)

        loadScript()
        .then(() => {

            const config = {
              "checkoutType": "iframe",
              "paymentIntentId": _paymentGetnetDetail.payment_intent_id,
              "accessToken": "Bearer " + _paymentGetnetDetail.payment_access_token
              // "paymentIntentId": "bf4a47d4-34b6-42cd-8921-45fef7446b7e",
              // "accessToken": "Bearer eyJ0eXAiOiJKV1QiLCJraWQiOiI1amhLMy9xK0ZpK0tTRkIrRUwwN3VhMFYwdGM9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJjaWRfMjYyZjJjMDItZDUwOS00MzYzLTk4ZmQtYmRhNzZmYzQ2N2E4IiwiY3RzIjoiT0FVVEgyX1NUQVRFTEVTU19HUkFOVCIsImF1ZGl0VHJhY2tpbmdJZCI6IjBkOGNhNjk3LWY4NzUtNDZiZC05NTdiLWYzZjdkZGZkOTkyMi01Mjk1MzMyNyIsInN1Ym5hbWUiOiJjaWRfMjYyZjJjMDItZDUwOS00MzYzLTk4ZmQtYmRhNzZmYzQ2N2E4IiwiaXNzIjoiaHR0cHM6Ly9hcGkucHJlLmdsb2JhbGdldG5ldC5jb20vb2F1dGgyL2NvbnN1bWVyIiwidG9rZW5OYW1lIjoiYWNjZXNzX3Rva2VuIiwidG9rZW5fdHlwZSI6IkJlYXJlciIsImF1dGhHcmFudElkIjoiVFBOMVFBd0VZNThmaXBaOWUzRmRldzAxNmc4IiwiYXVkIjoiY2lkXzI2MmYyYzAyLWQ1MDktNDM2My05OGZkLWJkYTc2ZmM0NjdhOCIsIm5iZiI6MTcxODkzMDQ4MSwiZ3JhbnRfdHlwZSI6ImNsaWVudF9jcmVkZW50aWFscyIsInNjb3BlIjpbImRpZ2l0YWwtcGF5bWVudHM6Y2hlY2tvdXQ6Y3VzdG9tIl0sImF1dGhfdGltZSI6MTcxODkzMDQ4MSwicmVhbG0iOiIvY29uc3VtZXIiLCJleHAiOjE3MTg5MzQwODEsImlhdCI6MTcxODkzMDQ4MSwiZXhwaXJlc19pbiI6MzYwMCwianRpIjoiZldwUnJ3ajVrSC1zd2VWNlFyQk8tcERteGVVIiwiY291bnRyeSI6IkFSIiwic2VsbGVySWQiOiI1YzMxY2NkNC0xNjgwLTQxZmYtOTQ2My04NDdmMWViNjBkNzMiLCJ0ZW5hbnQiOiJzYW50YW5kZXIifQ.C1opQhKVFcHXNHp4T-Q_li1q6gdS_AXusX1ksDLSqSTBfGtEwvCUH5KBSsZcjNBLngjbSy7x_gYua_cZXAiGui_xdbAsvIKbu7Hwbww423FduQrxNJPITC38izHAVTtUDO3hlpWX9aWRFZ3mLGmHIV2zVOJXKiOPh1Ea696Q8nIXqLvHvJqpJGe0PlJNdTXmSMDSSYt-e-VByor45VZQgqhMugrV_5lWIBygu1_VkDPiz534AvSgzZhE3Jqd9lcyUN_SuuOkzkC9mJQRRqxlq6-JKZSJ4ZXqj7U0YkHmaUlxTlMYse3hOdNqweB_NkGyNdii-bGA8ZRwfRxahWhdnw"
            };

            window.loader.init(config);

            const iframeSection = document.getElementById("iframe-section");
            const iframe = document.querySelector("iframe#digital-checkout-iframe");
            iframeSection.appendChild(iframe);

        }).catch(error => {
          console.error("Failed to load the script:", error);
        });
    }


  }, []);

  return (
    <Container fluid className="inner-page checkout-payment max-container">
      <div className="breadcrumb pl-0">
        Inicio / CARRITO DE COMPRAS / <span> CHECKOUT</span>
      </div>

      {paymentError &&
        <div className="text-center">
          <h3 className="pt-5 mt-5 mb-5 ">Error al conectar con Getnet</h3>
          <a href="/cuenta" className="payment-button">Volver a Mi Cuenta</a>
        </div>
      }

      <div id="iframe-section" style={{minHeight : 600, minWidth : 600}}>
          <div className="text-center">
            <h3 className="pt-5 mt-5 mb-5 ">Pago con Getnet</h3>
          </div>
      </div>



      {/* <h3 className="mb-4">FINALIZAR COMPRA</h3>
      <Row>
        <Col xs={12} className="green-box">
          <h4>DETALLES DEL PEDIDO</h4>
          <h5>Intent ID: {paymentGetnetDetail?.payment_intent_id ?? 'no'}</h5>
          <table>
            <tr>
              <td><strong>Producto</strong></td>
              <td><strong>Total</strong></td>
            </tr>
            { order?.orderItems.map((orderItem, i) => 
              <tr key={i}>
                <td>{orderItem.productTitle} x{orderItem.quantity}</td>
                <td>{orderItem.totalPrice.currency}{orderItem.totalPrice.amount}</td>
              </tr>
            )}
            <tr>
              <td><strong>Subtotal</strong></td>
              <td>{order?.productsPrice.currency}{order?.productsPrice.amount}</td>
            </tr>
            <tr>
              <td><strong>Descuento</strong></td>
              <td>- {order?.discountAmount.currency}{order?.discountAmount.amount}</td>
            </tr>
            <tr>
              <td><strong>Envío</strong></td>
              <td>{order?.shipmentAmount.currency}{order?.shipmentAmount.amount}</td>
            </tr>
            <tr className="total-row">
              <td>Total</td>
              <td>{order?.total.currency}{order?.total.amount}</td>
            </tr>
          </table>
        </Col>
        <Col className="text-center mt-5">
          <a href={order?.payment.payment_link} className="payment-button">PAGAR PEDIDO</a>
        </Col>
      </Row> */}
    </Container>
  )

}

export default CheckoutGetnet;