import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import {login, loginWithFacebook} from '../actions/auth'
import history from '../history'
import { Container, Row, Col, Button, FormGroup, FormCheck } from 'react-bootstrap'
import Alert from '../components/alert'
import FacebookLogin from 'react-facebook-login'

const Login = () => { 
  const [loginMessage, setLoginMessage] = useState(null)
  const [emailLogin, setEmailLogin] = useState('')
  const [passwordLogin, setPasswordLogin] = useState('')
  const [emailRegistration, setEmailRegistration] = useState('')
  const [passwordRegistration, setPasswordRegistration] = useState('')
  const [newsletterRegistration, setNewsletterRegistration] = useState(false)
  const dispatch = useDispatch()

  const onLoginSubmit = (evt) => {
    evt.preventDefault()
    dispatch(login(emailLogin,passwordLogin))
      .then((result) => {
        if(result.value.access_token != null){
          history.push('/cuenta/')
        }
      }).catch((error) => {
        if(error.response.status === 401){
          setLoginMessage('Credenciales inválidas. Verifique los datos ingresados')
        } else{
          setLoginMessage('Error al intentar ingresar. Si el error persiste comuniquese con nosotros')
        }
      })
  }

  const responseFacebook = (response) => {
    const { accessToken } = response
    dispatch(loginWithFacebook(accessToken))
      .then((result) => {
        if(result.value.access_token != null){
          history.push('/cuenta/')
        }
      })
  }

  const onRegistrationSubmit = (evt) => {
    evt.preventDefault()
    history.push({
      pathname: '/registracion',
      state: {
        email: emailRegistration,
        password: passwordRegistration,
        newsletterRegistration: newsletterRegistration
      }
    })
  }
  
  return (
    <Container fluid className="inner-page connect max-container">
      <div className="breadcrumb">
          <Link to="/">Inicio</Link> / INGRESO
      </div>
      <h3>Conectate con Entelequia</h3>
      <Row className="ml-0 mr-0">
        <Col lg={6} className="login">
            <div className="green-box">
                <h4>¡Accede desde tu cuenta!</h4>
                {loginMessage && 
                  <div className="w-full mb-4">
                    <Alert
                      color="bg-transparent border-red-500 text-red-500"
                      borderLeft
                      raised>
                      {loginMessage}
                    </Alert>
                  </div>
                }
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  scope="public_profile,email"
                  callback={responseFacebook}
                  icon={<img src="images/icons/face-grey.svg" alt=""/>}
                  textButton=''
                  cssClass="facebook-button"
                  isMobile={false}
                  />
                <p className="separator"><span>ó</span></p>
                <form onSubmit={onLoginSubmit}>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1"><img src="images/icons/envelop.svg" alt=""/></span>
                    </div>
                    <input 
                      type="email" 
                      name="email" 
                      className="form-control" 
                      placeholder="Correo eletrónico" 
                      value={emailLogin} 
                      onChange={(evt) => setEmailLogin(evt.target.value)}
                      required />
                  </div>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1"><img src="images/icons/lock.svg" alt=""/></span>
                    </div>
                    <input 
                      type="password" 
                      name="password" 
                      className="form-control" 
                      placeholder="Contraseña" 
                      value={passwordLogin} 
                      onChange={(evt) => setPasswordLogin(evt.target.value)}
                      required />
                  </div>  
                  <Button type="submit">Iniciar Sesión</Button>
                  <FormCheck className="remember">
                    <input type="checkbox" className="form-checkbox"/>
                    <label>Recuerdáme</label>
                  </FormCheck>
                </form>
                <Link to="/recuperar-clave" className="forgot-pass">¿Olvidaste la contraseña?</Link>
            </div>        
        </Col>
        <Col lg={6} className="registration">
          <div className="green-box">
            <h4>¿No tenes una cuenta? <strong>¡Registrate!</strong></h4>
            <p className="green-text">LLeva menos de un minuto y podes administrar todos tus pedidos.</p>
            <form onSubmit={onRegistrationSubmit}>
              <FormGroup>
                <label>Dirección de correo electrónico:</label>
                <input 
                  type="email" 
                  name="email" 
                  value={emailRegistration}
                  onChange={(evt) => setEmailRegistration(evt.target.value)}
                  className="form-control"
                  required
                  />
              </FormGroup>
              <FormGroup>
                <label>Contraseña</label>
                <input 
                  type="password" 
                  name="password" 
                  value={passwordRegistration}  
                  onChange={(evt) => setPasswordRegistration(evt.target.value)} 
                  className="form-control"
                  required
                  />
              </FormGroup>
              <p className="disclaimer">Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web, 
                gestionar el acceso a tu cuenta y otros propósitos descritos en nuestra <a href="/politicas" className="green-text">política de privacidad.</a></p>
              <FormCheck className="newsletter"> 
                <input 
                  type="checkbox" 
                  name="newsletterRegistration"
                  className="form-check-input"
                  checked={newsletterRegistration}
                  onChange={e => setNewsletterRegistration(!newsletterRegistration)}  
                  />
                <label className="form-form-check-label green-text">Quiero suscribirme para recibir todas las novedades y promociones de Entelequia por mail mediante el newsletter.</label>
              </FormCheck> 
              <Button type="submit">Registrarse</Button>
            </form>
          </div> 
        </Col>
      </Row>
    </Container>
  )
}

export default Login