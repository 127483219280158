import React,{useState, useEffect} from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import useAddToCart from '../hook/add-to-cart'
import useAddToFav from '../hook/add-to-fav'
import history from '../history'
import { FaCartPlus, FaHeart, FaEye } from 'react-icons/fa'

const mountedStyle = {
  animation: "inAnimation 250ms ease-in"
}
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards"
}

const ProductCardActions = ({productId, productSlug, showActions = false, buttonTheme = 'black'}) => {
  const [show, setShow] = useState(showActions)
  const [isAddingToCart, addToCart] = useAddToCart()
  const addToFav = useAddToFav()
  let publicUrl = process.env.PUBLIC_URL

  useEffect(() => {
    setShow(showActions)
  }, [showActions])

  return ( show && 
    <ListGroup horizontal 
      className="actions" 
      style={show ? mountedStyle : unmountedStyle}
      onAnimationEnd={() => {
        if (!show) setShow(false)
      }}>
      <ListGroup.Item>
        <Button onClick={() => addToCart(productId,1)}>
          {/* <img src={buttonTheme === 'black' ? 
            `${publicUrl}/images/icons/add-to-cart.png` : `${publicUrl}/images/icons/add-to-cart-white.svg`} alt="Agregar"/> */}
          <FaCartPlus className='action-icon action-cart'/>
        </Button>
      </ListGroup.Item>
      <ListGroup.Item>
        <Button onClick={() => addToFav(productId)}>
          {/* <img src={buttonTheme === 'black' ? 
            `${publicUrl}/images/icons/fav-off.png` : `${publicUrl}/images/icons/fav-white-off.svg`} alt="Favorito"/> */}
          <FaHeart className='action-icon action-fav'/>
        </Button>
      </ListGroup.Item>
      <ListGroup.Item>
        <Button onClick={() => {history.push(`/producto/${productSlug}`)}}>
          {/* <img src={buttonTheme === 'black'?
            `${publicUrl}/images/icons/see.png` : `${publicUrl}/images/icons/see-white.svg`} alt="Detalle"/> */}
          <FaEye className='action-icon action-see'/>  
        </Button>
      </ListGroup.Item>  
    </ListGroup>
  )  
}

export default ProductCardActions