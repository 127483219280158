import React from 'react'
import PropTypes from 'prop-types'

export const PageWithText = ({
  activeClassNames = 'btn btn-default text-button active-button',
  inactiveClassNames = 'btn btn-default text-button',
  children,
  active = false,
  onClick
}) => {
  if (active) {
    return (
      <button onClick={onClick} className={activeClassNames}>
        {children}
      </button>
    )
  }
  return (
    <button onClick={onClick} className={inactiveClassNames}>
      {children}
    </button>
  )
}

export const Page = ({
  activeClassNames = 'btn page active-button',
  inactiveClassNames = 'btn page',
  children,
  active = false,
  title = '',
  onClick
}) => {
  if (active) {
    return (
      <button onClick={onClick} className={activeClassNames} title={title}>
        {children}
      </button>
    )
  }
  return (
    <button onClick={onClick} className={inactiveClassNames} title={title}>
      {children}
    </button>
  )
}

export const Pages = ({items, active, onClick}) => (
  <>
    {items.length > 1 && 
      items.map(i => (
        <Page onClick={(e) => onClick(i+1)} active={i + 1 === active ? true : false} key={i}>
          {i + 1}
        </Page>
    ))}
  </>
)

Pages.propTypes = {
  items: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired
}

export const Pagination = ({
  items,
  active,
  previous = null,
  next = null,
  totalPages = 1,
  icons = false,
  onClick
}) => {
  if (icons) {
    return (
      <div className="flex flex-wrap items-center justify-start space-x-2 pagination mt-4">
        {previous && <Page onClick={onClick}>{previous}</Page>}
        <Pages onClick={onClick} items={items} active={active} />
        {next && <Page onClick={onClick}>{next}</Page>}
      </div>
    )
  }
  return (
    <div className="flex flex-wrap items-center justify-start space-x-2 pagination mt-4">
      <PageWithText onClick={() => onClick(1)} title="Primera">&lt;&lt;</PageWithText>
      { active !== 1 && <PageWithText onClick={() => onClick(active-1)} title="Previo">&lt;</PageWithText>}
      <button onClick={onClick} className="btn active-button">
        {active}
      </button>
      <span>de {totalPages}</span>
      { active+1 <= totalPages && <PageWithText onClick={() => onClick(active+1)} title="Siguiente">&gt;</PageWithText>}
      <PageWithText onClick={() => onClick(totalPages)} title="Ultima">&gt;&gt;</PageWithText>
    </div>
  )
}

Pagination.propTypes = {
  items: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  //previous: PropTypes.any.isRequired,
  //next: PropTypes.any.isRequired,
  icons: PropTypes.bool
}
