import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, FormCheck, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { showClientProfile } from '../actions/client'
import { confirmAddress } from '../actions/checkout'
import { createOrder } from '../actions/order'
import { showCart } from '../actions/cart'
import history from '../history'
import CountrySelect from '../components/country-select'
import ButtonLoader from '../components/button-loader'
import ReactPixel from 'react-facebook-pixel'

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Válido solo para pago en dolares. Para ello seleccione USD como moneda.
  </Tooltip>
);

const ADDRESS_INITIAL_STATE = {
  name: '',
  last_name: '',
  email: '',
  phone: '',
  dni: '',
  street_ln_1: '',
  street_ln_2: '',
  city: '',
  province: '',
  country: '',
  country_code: '',
  zip_code: '',

  street_opt: '',
  number_opt: '',
  floor_opt: '',
  apartment_opt: '',
}

const Checkout = () => {
  const { profile } = useSelector(state => state.showClientProfile)
  const { cart } = useSelector(state => state.showCart)
  const [clientAddress, setClientAddress] = useState({
    bill_address: ADDRESS_INITIAL_STATE,
    ship_address: ADDRESS_INITIAL_STATE,
    sameBillingAddress: true,
  })
  const [clientNotes, setClientNotes] = useState('')
  const [shipment, setShipment] = useState("1")
  const [selectedOfficeId, setSelectedOfficeId] = useState(null)
  const [payment, setPayment] = useState("Transferencia")
  const [validationMessage, setvalidationMessage] = useState('')
  const [addressValidationMessage, setAddressValidationMessage] = useState('')
  const [userCurrency, setUserCurrency] = useState('ARS')
  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false)
  const [isAddressConfirmed, setIsAddressConfirmed] = useState(false)
  const [isMakingOrder, setIsMakingOrder] = useState(false)
  const myRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!cart || cart.id == null) {
      history.push('/cart')
    }
    if (!profile) {
      dispatch(showClientProfile())
    }
    setUserCurrency(localStorage.getItem('entelequia-currency') ?? 'ARS');
  }, [])

  useEffect(() => {
    if (cart) {
      let productsIds = cart.cartItems.map(cartItem => cartItem.product.id)
      let productsAndQuantity = cart.cartItems.map(cartItem => ({ 'id': cartItem.product.id, 'quantity': cartItem.quantity }))
      ReactPixel.init('261944151355410')
      ReactPixel.track('InitiateCheckout', { content_ids: productsIds, contents: productsAndQuantity })
    }
  }, [cart])

  useEffect(() => {
    if (profile) {
      setClientAddress({
        ...clientAddress, ship_address: profile.ship_address ?? ADDRESS_INITIAL_STATE,
        bill_address: profile.bill_address ?? ADDRESS_INITIAL_STATE
      })
    }
  }, [profile])

  const onAddresChange = (addressType, e) => {
    let { name, value } = e.target
    let address = { ...clientAddress[addressType] }
    address[name] = value
    setClientAddress({ ...clientAddress, [addressType]: address })
  }

  const onCountryChange = (addressType, option) => {
    let newAddress = clientAddress[addressType]
    newAddress.country = option.value
    newAddress.country_code = option.code
    setClientAddress({ ...clientAddress, [addressType]: newAddress })
  }

  const createShipStreetLn1 = (address) => {
    address.ship_address.street_ln_1 = address.ship_address.street_opt + ' ' + address.ship_address.number_opt;
    if (address.ship_address.floor_opt != '') {
      address.ship_address.street_ln_1 += ' Piso ' + address.ship_address.floor_opt;
    }
    if (address.ship_address.apartment_opt != '') {
      address.ship_address.street_ln_1 += ' Depto. ' + address.ship_address.apartment_opt;
    }
    return address;
  }

  const onConfirmAddress = evt => {
    evt.preventDefault()
    setIsLoadingConfirmation(true)
    const address = createShipStreetLn1(clientAddress)
    dispatch(confirmAddress(address))
      .then((data) => {
        setIsAddressConfirmed(true)
        myRef.current.scrollIntoView()
        setAddressValidationMessage("")
      })
      .catch((error) => {
        setIsAddressConfirmed(false)
        let { data, status } = error.response
        if (status === 422) {
          setAddressValidationMessage(data.message)
        } else {
          setAddressValidationMessage('Se produjo un error al confirmar la dirección. Si el problema persiste, comuníquese con nostros.')
        }
      })
      .finally(() => {
        dispatch(showCart(true))
        setShipment("1")
        setIsLoadingConfirmation(false)
      })
  }

  const onMakeOrder = () => {
    if (!isAddressConfirmed) {
      alert('Ante de realizar el pedido, revise sus datos personales y haga click en confirmar datos.')
    } else {
      setIsMakingOrder(true)
      const address = createShipStreetLn1(clientAddress)
      dispatch(createOrder(address, shipment, selectedOfficeId, payment, clientNotes))
        .then(response => {
          let { order, bankData } = response.value
          if (order.payment.payment_method == 'Transferencia') {
            history.push('/cuenta/checkout-complete', {
              order: order,
              bankData,
            })
          } else if (order.payment.payment_method == 'Getnet') {
            console.log("response.value", response.value);
            let { paymentGetnetDetail } = response.value
            console.log(paymentGetnetDetail, paymentGetnetDetail)
            history.push('/cuenta/checkout-getnet', {
              order: order,
              paymentGetnetDetail: paymentGetnetDetail,
            })
          } else {
            if (order?.payment.payment_link) {
              // redirect to payment gateway
              window.location.href = order.payment.payment_link
            } else {
              history.push('/cuenta/checkout-make-payment', {
                order: order
              })
            }
          }
          dispatch(showCart(true))
        }).catch(error => {
          setIsMakingOrder(false)
          let { data , status } = error.response
          if (status === 422) {
            setvalidationMessage(data.message)
          } else {
            setvalidationMessage('Se produjo un error al realizar el pedido. Si el problema persiste, comuníquese con nostros.')
          }
        })
    }
  }

  const calcSubtotal = () => {
    if (shipment === "1" && isAddressConfirmed) {
      return cart?.totalPrice.currency + " " + cart?.totalPrice.amount;
    } else if (shipment === "11" && isAddressConfirmed) {
      if(cart?.isFreeShip){
        return cart?.totalPrice.currency + " " + cart?.totalPrice.amount;
      }
      return cart?.totalPrice.currency + " " + (cart?.productPrice.amount + cart?.possibleShippingOffices?.shipment_cost - (cart?.couponAmount.amount + cart?.giftCardAmount.amount + cart?.discountAmount.amount));
    } else {
      return cart?.productPrice.currency + " " + (cart?.productPrice.amount - (cart?.couponAmount.amount + cart?.giftCardAmount.amount + cart?.discountAmount.amount));
    }
  }

  return (
    <Container fluid className="inner-page checkout max-container">
      <div className="breadcrumb pl-0">
        Inicio / CARRITO DE COMPRAS / <span> CHECKOUT</span>
      </div>
      <h3>REALIZAR COMPRA</h3>
      <Row>
        <Col md={6} lg={4} className="data">
          <div className="box">
            <h4>Datos Personales</h4>
            <form onSubmit={onConfirmAddress}>
              <div className="fields">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={clientAddress.ship_address?.name}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Nombre"
                  required />
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  value={clientAddress.ship_address?.last_name}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Apellido"
                  required />
                <input
                  type="text"
                  className="form-control"
                  name="dni"
                  value={clientAddress.ship_address?.dni}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Documento de identidad"
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={clientAddress.ship_address?.phone}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Télefono / Celular"
                  required />
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={clientAddress.ship_address?.email}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Email"
                  required />
                {/* <input 
                  type="text" 
                  className="form-control" 
                  name="street_ln_1" 
                  value={clientAddress.ship_address?.street_ln_1}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Línea de calle 1"
                  required
                  />
                <input 
                  type="text" 
                  className="form-control" 
                  name="street_ln_2" 
                  value={clientAddress.ship_address?.street_ln_2}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Piso / Depto. / Entre Calles (Opcional)"
                  /> */}

                <div style={{ display: 'flex', gap: '10px' }}>
                  <input
                    type="text"
                    className="form-control"
                    name="street_opt"
                    value={clientAddress.ship_address?.street_opt}
                    onChange={(e) => onAddresChange('ship_address', e)}
                    placeholder="Calle"
                    required
                  />
                  <input
                    type="number"
                    className="form-control"
                    name="number_opt"
                    value={clientAddress.ship_address?.number_opt}
                    onChange={(e) => onAddresChange('ship_address', e)}
                    placeholder="Número"
                    required
                  />
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                  <input
                    type="text"
                    className="form-control"
                    name="floor_opt"
                    value={clientAddress.ship_address?.floor_opt}
                    onChange={(e) => onAddresChange('ship_address', e)}
                    placeholder="Piso (opcional)"
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="apartment_opt"
                    value={clientAddress.ship_address?.apartment_opt}
                    onChange={(e) => onAddresChange('ship_address', e)}
                    placeholder="Depto. (Opcional)"
                  />
                </div>

                <input
                  type="text"
                  className="form-control"
                  name="street_ln_2"
                  value={clientAddress.ship_address?.street_ln_2}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Entre Calles (Opcional)"
                />

                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={clientAddress.ship_address?.city}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Localidad / Ciudad"
                  required />
                <input
                  type="text"
                  className="form-control"
                  name="province"
                  value={clientAddress.ship_address?.province}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Provincia / Estado"
                  required />
                <CountrySelect
                  name="country"
                  value={clientAddress.ship_address?.country}
                  onCountrySelect={option => onCountryChange('ship_address', option)}
                  isRequired={true}
                />
                <input
                  type="text"
                  className="form-control"
                  name="zip_code"
                  value={clientAddress.ship_address?.zip_code}
                  onChange={(e) => onAddresChange('ship_address', e)}
                  placeholder="Código Postal"
                  style={{ marginBottom: 0 }} />

                <div style={{ color: "#43393b", fontSize: "13px", margin: "5px 0 20px 5px" }}>
                  Consulte su Código Postal <a href='https://codigopostal.com.ar/' target='_blank' style={{ color: "#16b54c" }}>aquí</a>
                </div>

                <div className="button-holder">
                  <input
                    type="checkbox"
                    className="ml-1 mr-2"
                    name="same_billing_address"
                    checked={clientAddress.sameBillingAddress}
                    onChange={() => setClientAddress({ ...clientAddress, sameBillingAddress: !clientAddress.sameBillingAddress })} />
                  <label> Usar misma dirección para la facturación</label>
                </div>
                {!clientAddress.sameBillingAddress &&
                  <>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={clientAddress.bill_address?.name}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Nombre"
                      required={clientAddress.sameBillingAddress ? '' : 'required'}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="last_name"
                      value={clientAddress.bill_address?.last_name}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Apellido"
                      required={clientAddress.sameBillingAddress ? '' : 'required'}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="dni"
                      value={clientAddress.bill_address?.dni}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Documento de identidad"
                      required={clientAddress.sameBillingAddress ? '' : 'required'}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={clientAddress.bill_address?.phone}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Teléfono / Celular"
                      required={clientAddress.sameBillingAddress ? '' : 'required'}
                    />
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={clientAddress.bill_address?.email}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Email"
                      required={clientAddress.sameBillingAddress ? '' : 'required'}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="street_ln_1"
                      value={clientAddress.bill_address?.street_ln_1}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Línea de calle 1"
                      required={clientAddress.sameBillingAddress ? '' : 'required'}
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="street_ln_2"
                      value={clientAddress.bill_address?.street_ln_2}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Línea de calle 2"
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={clientAddress.bill_address?.city}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Localidad / Ciudad"
                      required={clientAddress.sameBillingAddress ? '' : 'required'} />
                    <input
                      type="text"
                      className="form-control"
                      name="province"
                      value={clientAddress.bill_address?.province}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Provincia / Estado"
                      required={clientAddress.sameBillingAddress ? '' : 'required'} />
                    <CountrySelect
                      name="country"
                      value={clientAddress.bill_address?.country}
                      onCountrySelect={(option) => onCountryChange('bill_address', option)}
                      isRequired={clientAddress.sameBillingAddress ? false : true} />
                    <input
                      type="text"
                      className="form-control"
                      name="zip_code"
                      value={clientAddress.bill_address?.zip_code}
                      onChange={(e) => onAddresChange('bill_address', e)}
                      placeholder="Código Postal" />
                  </>
                }
              </div>
              <div className="note">
                <p>Notas del pedido</p>
                <textarea
                  className="form-control"
                  name="order_note"
                  value={clientNotes}
                  onChange={(e) => setClientNotes(e.target.value)}
                  placeholder="Notas sobre tu pedido, por ejemplo, notas especiales para la entrega."></textarea>
              </div>
              <ButtonLoader
                isLoading={isLoadingConfirmation}
                loadingMessage="Cargando"
                type="submit"
                className="confirm-button">
                Confirmar datos
              </ButtonLoader>
            </form>
            {addressValidationMessage != '' &&
              <p className='pl-2' style={{ color: 'red', marginTop: 10 }}>{addressValidationMessage}</p>}
          </div>
        </Col>
        <Col md={6} lg={4} className="summary">
          <div className="box" ref={myRef}>
            <h4>Tu pedido</h4>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th style={{ textAlign: 'right' }}>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {cart?.cartItems.map((cartItem, i) =>
                  <tr key={i}>
                    <td className="product">{cartItem.product.title} {cartItem.quantity > 1 ? "": `(x${cartItem.quantity})`}</td>
                    <td className="price">{cartItem.product.price.currency} {cartItem.product.price.amount * cartItem.quantity}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="shipment">
              <h5>Envio</h5>
              {
                (shipment !== "1" && shipment !== "11") ?
                  <span className="free-ship" style={{ color: '#16b54c' }}>{cart?.productPrice.currency} 0</span>
                  :
                  isAddressConfirmed ?
                    cart?.isFreeShip ?
                      <span className="free-ship" style={{ color: '#16b54c' }}>GRATIS</span>
                      :
                      <>
                        {
                          shipment === "1" ?
                            <span className="free-ship">{cart?.shipmentAmount?.currency} {cart?.shipmentAmount?.amount}</span>
                            :
                            <span className="free-ship">{cart?.shipmentAmount?.currency} {cart?.possibleShippingOffices?.shipment_cost}</span>
                        }
                      </>
                    :
                    ''
              }

              <FormCheck>
                <input
                  id='radio_retiro_centro'
                  type="radio"
                  name="shippment"
                  value="2"
                  className="form-check-input"
                  checked={shipment === "2"}
                  onChange={(e) => setShipment(e.target.value)} />
                <label htmlFor='radio_retiro_centro' className="form-form-check-label">Retiro por sucursal Centro</label>
              </FormCheck>
              <FormCheck>
                <input
                  id='radio_retiro_belgrano'
                  type="radio"
                  name="shippment"
                  value="3"
                  className="form-check-input"
                  checked={shipment === "3"}
                  onChange={(e) => setShipment(e.target.value)} />
                <label htmlFor='radio_retiro_belgrano' className="form-form-check-label">Retiro por sucursal Belgrano</label>
              </FormCheck>


              <FormCheck>
                <input
                  id='radio_envio_domicilio'
                  type="radio"
                  name="shippment"
                  value="1"
                  className="form-check-input"
                  checked={shipment === "1"}
                  onChange={(e) => setShipment(e.target.value)} />
                <label htmlFor='radio_envio_domicilio' className="form-form-check-label">Envío a domicilio</label>
              </FormCheck>


              <FormCheck>
                <input
                  id='radio_punto_retiro'
                  type="radio"
                  name="shippment"
                  value="11"
                  className="form-check-input"
                  checked={shipment === "11"}
                  onChange={(e) => { setShipment(e.target.value); setSelectedOfficeId(cart?.possibleShippingOffices?.offices[0]?.id ?? null) }}
                  disabled={(!isAddressConfirmed || cart?.possibleShippingOffices?.offices.length == 0) ? 'disabled' : ''}
                />
                <label htmlFor='radio_punto_retiro' className="form-form-check-label">Punto de retiro</label>
              </FormCheck>

              {
                (isAddressConfirmed == false) ?
                  (
                    <div className='sucursales_message'>Confirme dirección para ver las opciones.</div>
                  ) : (
                    (cart?.possibleShippingOffices?.offices.length == 0) ?
                      (
                        <div className='sucursales_message sucursales_message_red'>No hay puntos de retiro disponibles.</div>
                      ) : (
                        <>
                          <div className='sucursales_checkboxes'>
                            {cart?.possibleShippingOffices?.offices.map((office, index) => {
                              return (
                                <FormCheck key={office.id}>

                                  <input
                                    id={'radio_punto_retiro__' + office.id}
                                    type="radio"
                                    name="selected_office_id"
                                    value={office.id}
                                    className="form-check-input"
                                    checked={shipment === "11" && selectedOfficeId == office.id}
                                    onChange={(e) => { setShipment("11"); setSelectedOfficeId(e.target.value) }}
                                  />
                                  <label htmlFor={'radio_punto_retiro__' + office.id}>{office.full_address} <strong>{office.localidad}, {office.provincia}</strong></label>
                                </FormCheck>
                              )
                            })}
                          </div>
                          <div className='sucursales_message'>Para punto de retiro el tiempo aproximado es de 5 días hábiles.</div>
                        </>
                      )
                  )
              }

            </div>
            <div className="total">
              <p>SUBTOTAL</p>
              <p className="price">{calcSubtotal()}</p>
            </div>
          </div>
        </Col>
        <Col lg={4} className="payment">
          <div className="box grey">
            <h4>Elige el método de pago</h4>
            <p>Paga con el medio de pago que prefieras</p>
            <div className="button-holder">
              <input
                id='radio_transferencia'
                type="radio"
                name="payment"
                value="Transferencia"
                checked={payment === "Transferencia"}
                onChange={(e) => setPayment(e.target.value)} />
              <label htmlFor='radio_transferencia'>Transferencia bancaria directa</label>
            </div>
            <div className="button-holder">
              <input 
                id='radio_getnet'
                type="radio" 
                name="payment"
                value="Getnet"    
                checked={payment === "Getnet"}
                disabled={ userCurrency == 'USD' ? 'disabled' : ''} 
                onChange={(e) => setPayment(e.target.value)}/>
              <label htmlFor='radio_getnet'>Pago con Tarjeta (Getnet)</label>  
            </div>
            <div className="button-holder">
              <input
                id='radio_mercado_pago'
                type="radio"
                name="payment"
                value="Mercado Pago"
                checked={payment === "Mercado Pago"}
                disabled={userCurrency == 'USD' ? 'disabled' : ''}
                onChange={(e) => setPayment(e.target.value)} />
              <label htmlFor='radio_mercado_pago'>Mercado Pago</label>
            </div>
            <div className="button-holder">
              <input
                id='radio_paypal'
                type="radio"
                name="payment"
                value="Paypal"
                disabled={userCurrency == 'ARS' ? 'disabled' : ''}
                checked={payment === "Paypal"}
                onChange={(e) => setPayment(e.target.value)} />
              <label htmlFor='radio_paypal'>PayPal </label>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}>
                <span variant="success" style={{ marginLeft: '10px', border: '1px solid #fff', borderRadius: '100%', padding: '2px 6px', color: '#fff', cursor: 'pointer' }}>?</span>
              </OverlayTrigger>
            </div>
            <p>Tus datos personales se utilizarán para procesar tu pedido, mejorar tu experiencia en esta web,
              gestionar el acceso a tu cuenta y otros propósitos descritos en nuestra <a href="/politicas">política de privacidad.</a></p>
          </div>
          {payment === "Mercado Pago" &&
            <img src="https://imgmp.mlstatic.com/org-img/banners/ar/medios/online/468X60.jpg"
              title="Mercado Pago - Medios de pago" alt="Mercado Pago - Medios de pago"
              width="468" height="60" style={{ maxWidth: '100%', marginBottom: '20px' }} />
          }
          <ButtonLoader
            isLoading={isMakingOrder}
            loadingMessage="Realizando pedido"
            type="button"
            className={`confirm-button ${!isAddressConfirmed ? 'disabled' : ''}`}
            onClick={() => onMakeOrder()}>
            Realizar pedido
          </ButtonLoader>
          {validationMessage != '' &&
            <p className='pl-2' style={{ color: 'red' }}>{validationMessage}</p>}
        </Col>
      </Row>
    </Container>
  )
}

export default Checkout