import axios from 'axios'

export const GET_HOME_CONTENT = 'GET_HOME_CONTENT'

export const getHomeContent = () => ({
  type: GET_HOME_CONTENT,
  payload: axios.get('/home',{
    params: {
      currency: localStorage.getItem('entelequia-currency')
    }
  })
    .then(response => response.data)
    .catch(error => Promise.reject(error))
})