import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from './reducers'
import promise from 'redux-promise-middleware'

const middleware = [thunk, promise];

if (process.env.NODE_ENV !== 'production') middleware.push(createLogger());

export const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
)