import React, {useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col, ListGroup, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { findFooterCategories } from '../actions/category'
import history from '../history'

const Footer = () => {
  let publicUrl = process.env.PUBLIC_URL
  const [categories, setCategories] = useState([])
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(findFooterCategories())
      .then(response => {
        let footerCategories = response.value
        setCategories(footerCategories)
      })
  },[])

  return (
    <footer>
      <Row className="prefooter" style={{background: "url('"+publicUrl+"/images/bkg_footer.png')"}}></Row>
      <Container fluid>
        <Row className="contact">
          <Col xs={12} lg={4} xl={3} className="text-lg-left text-center">
            <img src={`${publicUrl}/images/logo.png`} alt="Entelequia" className="img-fluid logo-footer"/>
            <p className="ml-auto mr-auto ml-lg-0">Recibí nuestro boletín y enterate primero de todas las novedades, promociones y descuentos especiales.</p>
            <a href="https://optin.myperfit.com/subscribe/entelequia/Ki1VK9NH" target="_blank" rel="noreferrer" className="button">¡Quiero suscribirme!</a>
            <p className='mt-4 pt-2'>Distribución a traves de Bam Comics SRL</p>
          </Col>
          <Col xs={12} md={10} lg={8} xl={6} className="locales offset-md-1 offset-lg-0 mb-5 mb-lg-0">
            <Row>
              <Col xs={12} sm={6}>
                <h4 className="ml-auto mr-auto ml-sm-0 mr-sm-0 text-center text-sm-left"><strong>Entelequia</strong> Centro</h4>
                <ListGroup className="ml-auto mr-auto ml-sm-0 mr-sm-0">
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/marker_green.png`} alt="Ubicación"/>
                    </div>
                    Uruguay 341
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/phone_green.png`} alt="Teléfono"/>
                    </div>
                    (011) 4372-7282
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/envelop_green.png`} alt="Mail"/>
                    </div>
                    info@entelequia.com.ar
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/calendar_green.png`} alt="Horario"/>
                    </div>
                    Lunes a viernes: 10:00 - 19:00 hs.<br/>
                    Sábado: 11:00 - 19:00 hs.
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col xs={12} sm={6} className="mt-4 mt-sm-0 mt-5 mt-sm-0">
                <h4 className="ml-auto mr-auto ml-sm-0 mr-sm-0 text-center text-sm-left"><strong>Entelequia</strong> Belgrano</h4>
                <ListGroup className="ml-auto mr-auto ml-sm-0 mr-sm-0">
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/marker_green.png`} alt="Ubicación"/>
                    </div>
                    Juramento 2584
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/phone_green.png`} alt="Teléfono"/>
                    </div>
                    (011) 4788-4521
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/envelop_green.png`} alt="Mail"/>
                    </div>
                    belgrano@entelequia.com.ar
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="img-cont">
                      <img src={`${publicUrl}/images/icons/calendar_green.png`} alt="Horario"/>
                    </div>
                    Lunes a viernes: 10:00 - 19:00 hs.<br/>
                    Sábado: 11:00 - 19:00 hs.
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
          <Col xl={3} className="redes d-none d-xl-block">
            <h4><strong>Seguinos</strong> en nuestras redes</h4>
            <ListGroup horizontal>
              <ListGroup.Item>
                <a href="https://www.facebook.com/entelequia.comicbookstore/" target="_blank" rel="noreferrer">
                  <img src={`${publicUrl}/images/icons/facebook_green.png`} alt="Facebook"/>
                </a>
              </ListGroup.Item>
              <ListGroup.Item>
                <a href="https://www.instagram.com/entelequiagram/" target="_blank" rel="noreferrer">
                  <img src={`${publicUrl}/images/icons/instagram_green.png`} alt="Instagram"/>
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
        <Row className="links">
          <Col xs={12} md={10} lg={12} xl={8} className="offset-md-1 offset-lg-0 mb-5 mb-lg-0">
            <Row>
              <Col sm={8} className="categorias pd-0 pl-lg-4">
                <h4 className="text-center text-sm-left">Categorías</h4>
                <Row>
                  {
                    categories.length > 0 &&
                    [...Array(Math.ceil(categories.length / 4)).keys()].map((el,i) => {
                        const [a, b, c, d] = categories.slice(el * 4, (el+1) * 4)
                        return <Col xs={6} md={4} lg={3} key={i}>
                          <ListGroup className="ml-auto mr-auto ml-sm-0 mr-sm-0">
                            <ListGroup.Item><Link to={`/productos/${a?.slug}`}>{a?.name}</Link></ListGroup.Item>
                            <ListGroup.Item><Link to={`/productos/${b?.slug}`}>{b?.name}</Link></ListGroup.Item>
                            <ListGroup.Item><Link to={`/productos/${c?.slug}`}>{c?.name}</Link></ListGroup.Item>
                            <ListGroup.Item><Link to={`/productos/${d?.slug}`}>{d?.name}</Link></ListGroup.Item>
                          </ListGroup>
                        </Col>
                    })  
                  }
                  <Col xs={6} md={4} lg={3}>
                    <ListGroup className="ml-auto mr-auto ml-sm-0 mr-sm-0">
                      <ListGroup.Item><Link to="/ofertas">Ofertas</Link></ListGroup.Item>
                      <ListGroup.Item><Link to="/recomendados">Recomendados</Link></ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={4} className="sitemap pl-3 pl-md-5 mt-5 mt-sm-0">
                <h4 className="text-center text-sm-left">Información útil</h4>
                <Row>
                  <Col xs={6} sm={12}> 
                    <ListGroup className="ml-auto mr-auto ml-sm-0 mr-sm-0">
                      <ListGroup.Item className="pt-1"><Link to="/nosotros">Nosotros</Link></ListGroup.Item>
                      <ListGroup.Item className="pt-0"><Link to="/como-comprar">Cómo comprar</Link></ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col xs={6} sm={12}>
                    <ListGroup className="ml-auto mr-auto ml-sm-0 mr-sm-0">
                      <ListGroup.Item className="pt-0"><Link to="/novedades">Novedades</Link></ListGroup.Item>
                      <ListGroup.Item className="pt-0"><Link to="/cuenta/favoritos">Favoritos</Link></ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={10} lg={12} xl={4} className="pagos offset-md-1 offset-lg-0 mt-5 mt-xl-0 text-center text-md-left">
            <h4 className="mb-4 mb-md-0">Medios de pago</h4>
            <img src={`${publicUrl}/images/icons/paypal.png`} alt="PayPal"/>
            <img src={`${publicUrl}/images/icons/master.png`} alt="Mastercard"/>
            <img src={`${publicUrl}/images/icons/meli.png`} alt="Mercado Libre"/>
            <img src={`${publicUrl}/images/icons/visa.png`} alt="Visa"/>
            <img src={`${publicUrl}/images/icons/amex.png`} alt="American Express"/>
            <img src={`${publicUrl}/images/icons/maestro.png`} alt="Maestro"/>
          </Col>      
        </Row>
      </Container>
      <a href="https://api.whatsapp.com/send?phone=541161898533&text=Hola%20*Entelequia%20Comic%20Book%20Store*" 
        className="wap-button" 
        target="_blank"
        rel="noreferrer">
		    <img src={`${publicUrl}/images/wapp.svg`} className="img-fluid" alt="Whats'app"/>
	    </a>
    </footer>
  )
}

export default Footer