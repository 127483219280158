import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ProductCardActions from './product-card-actions'

const ProductCard = ({product, className}) => {
  const [showActions, setShowActions] = useState(false)
  let apiPublicFolder = process.env.REACT_APP_API_PUBLIC_FOLDER + 'storage/'
  let publicUrl = process.env.PUBLIC_URL
  // console.log(product);
  return (
    <div 
      className={`product-card product-card-2 ${className}`}
      onMouseEnter={() => setShowActions(true)} 
      onMouseLeave={() => setShowActions(false)}>
      {/* <p className="category">{product?.category?.name}</p> */}
      <Link to={`/producto/${product?.slug}`} className="image-link">
      {  product?.images?.length ? 
        <img className="product-image img-fluid" src={`${apiPublicFolder}${product.images[0].path}`} alt="Imagen de producto" />
        :        
        <img className="product-image img-fluid" src={`${publicUrl}/images/nofoto.png`} alt="Imagen de producto" />       
      }
      </Link>
      <Link to={`/producto/${product?.slug}`}>
        <h4 className="title">{product?.title}</h4>
      </Link>
      <p className="first-category">{(product?.categories?.length > 0) && product.categories[0].name}</p>
      {product?.discount_percent ? 
        <div className="price-holder"> 
          <p className="price">{product?.priceWithDiscount.currency} {product.priceWithDiscount.amount}</p>
          <div><del>{product?.price.currency} {product?.price.amount}</del></div>
        </div>
      :
        <div className="price-holder">
          <p className='price'>{product?.price.currency} {product?.price.amount}</p>
        </div>
      }
      {
        product?.stock && product.stock == 0 &&
          <p className='without-stock' translate='no'>Sin stock</p>
      }
      <ProductCardActions showActions={showActions}  productId={product.id} productSlug={product.slug}/>
    </div>
  )
}

export default ProductCard