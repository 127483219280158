import React from 'react'
import { Row, Col, ListGroup, Button } from 'react-bootstrap'
import { Link } from "react-router-dom"

const SearchBreadcrum = ({ 
  order = 'recent', 
  show = 2, 
  title='LISTADO DE PRODUCTOS', 
  appliedFilters, 
  handleRemoveFilter, 
  handleChangeOrder, 
  handleChangeShow }) => {

  let publicUrl = process.env.PUBLIC_URL

  const onChangeOrder = e => {
    handleChangeOrder(e.target.value)
  } 

  const onChangeShow = index => {
    handleChangeShow(index) 
  }

  const capitalizeLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
  
  return (
    <>
      <Row className="product-breadcrumb">
        <Col xs={12} md={6} className="path">
          <h4><span><Link to="/">Inicio</Link></span> / <span>{title}</span></h4>
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={5} className="mode-show">
              <ListGroup horizontal className="justify-content-md-end">
                <ListGroup.Item className="d-md-none">
                  <Button
                    onClick={() => onChangeShow(1)}>
                    <img 
                     src={`${publicUrl}/images/icons/list/result_list_${show === 1 ? "on.png" : "off.png"} `}
                      alt="Listado"/>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button
                    onClick={() => onChangeShow(2)}>
                    <img 
                      src={`${publicUrl}/images/icons/list/result_two_${show === 2 ? "on.png" : "off.png"} `}
                      alt="Ver 2"/>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item className="d-none d-md-inline">
                  <Button
                    onClick={() => onChangeShow(3)}>
                    <img 
                      src={`${publicUrl}/images/icons/list/result_three_${show === 3 ? "on.png" : "off.png"} `}
                      alt="Ver 3"/>
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item className="d-none d-md-inline">
                  <Button
                    onClick={() => onChangeShow(4)}>
                    <img 
                      src={`${publicUrl}/images/icons/list/result_four_${show === 4 ? "on.png" : "off.png"} `} 
                      alt="Ver 4"/>
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xs={7} className="pt-1 pl-0">
              <select 
                className="form-control order" 
                name="order" 
                value={order}
                onChange={(e) => onChangeOrder(e)}>
                <option value="recent">Orden más reciente</option>
                <option value="price">Orden más económicos</option>
                <option value="title">Orden alfabético</option>
              </select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          { ( appliedFilters.hasOwnProperty('precioMax') || appliedFilters.hasOwnProperty('formato') 
              ||  appliedFilters.hasOwnProperty('idioma') ||  appliedFilters.hasOwnProperty('editorial')
              ||  appliedFilters.hasOwnProperty('autor') ) || appliedFilters.hasOwnProperty('q') &&
            <ListGroup horizontal className="applied-filters">
              <ListGroup.Item>
                <Button className="delete-all" onClick={() => handleRemoveFilter("all")}>
                  X BORRAR FILTROS
                </Button>
              </ListGroup.Item>
              
              {
                appliedFilters.hasOwnProperty('q')  &&
                <ListGroup.Item>
                  <Button onClick={() => handleRemoveFilter("q")}>
                    X Buscando: {appliedFilters.q}
                  </Button>
                </ListGroup.Item>    
              }
              {
                appliedFilters.hasOwnProperty('precioMax')  &&
                <ListGroup.Item>
                  <Button onClick={() => handleRemoveFilter("precio")}>
                    X Precio ${appliedFilters.precioMin}- ${appliedFilters.precioMax}
                  </Button>
                </ListGroup.Item>    
              }
              {
                appliedFilters.hasOwnProperty('idioma')  &&
                <ListGroup.Item>
                  <Button onClick={() => handleRemoveFilter("idioma")}>
                    X {appliedFilters.idioma}
                  </Button>
                </ListGroup.Item>    
              }
              {
                appliedFilters.hasOwnProperty('formato')  &&
                <ListGroup.Item>
                  <Button onClick={() => handleRemoveFilter("formato")}>
                    X {appliedFilters.formato}
                  </Button>
                </ListGroup.Item>    
              }
              {
                appliedFilters.hasOwnProperty('editorial')  && 
                <ListGroup.Item>
                  <Button onClick={() => handleRemoveFilter("editorial")}>
                    X {capitalizeLetter(appliedFilters.editorial.replaceAll('-', ' '))}
                  </Button>
                </ListGroup.Item>
              }
              {
                appliedFilters.hasOwnProperty('autor')  && 
                <ListGroup.Item>
                  <Button onClick={() => handleRemoveFilter("autor")}>
                    X {capitalizeLetter(appliedFilters.autor.replaceAll('-', ' '))}
                  </Button>
                </ListGroup.Item>
              }
            </ListGroup>
          }
        </Col>
      </Row>
    </>
  )
}

export default SearchBreadcrum